export const ENGRAVING_PRICE = 41;
export const DIAMOND_CATEGORIES = [3, 139];
export const GEMSTONE_CATEGORIES = [3, 141];
export const LABGROWN_CATEGORIES = [3, 140];
export const ENGAGEMENT_RINGS = 4;
export const LOCAL_STOCKS = ['JMD', 'JMDL', 'DCVY', 'GEM'];

export const DefaultMetals =
  '190,191,192,193,194,195,196,197,198,199,200,201,126,127,128,5635,5650,5651,5652,5653,5654';
export const DefaultStyles =
  '122,118,120,117,121,5636,5637,5638,5639,5640,5641,5642,5643';

export const PRODUCT_MAGE_URL = process.env
  .NEXT_PUBLIC_MAGE_PRODUCT_MEDIA_URL as string;

import {
  MetalColor14KWhiteGold,
  MetalColor14KWhiteYellowGold,
  MetalColor14KWhiteRoseGold,
  MetalColor14KRoseGold,
  MetalColor18KYellowGold,
  MetalColor18KWhiteGold,
  MetalColor18KRoseGold,
  MetalColor18KWhiteRoseGold,
  MetalColor18KWhiteYellowGold,
  MetalColor18KWhiteYellowRoseGold,
  MetalColorPT900,
  MetalColorPT950,
  MetalColor18KVermeilYellowGold,
  MetalColor18KVermeilRoseGold,
  MetalColor925Silver,
} from '@components/icons/jewel/metal-color';

import {
  FontHarringtonIcon,
  FontLaurenScriptRegularIcon,
  FontTimesNewRomanIcon,
  FontTungaIcon,
  FontMonotypeCorsivaIcon,
} from '@components/icons/jewel/index';

import {
  MetalColorOptionsType,
  TProduct,
  TSteps,
  TStyles,
} from '@services/mage/catalog-product-types';
import { setUrl } from '@utils/url';

export const MetalColorOptions: MetalColorOptionsType = {
  '14KY': { title: '14K Yellow Gold', icon: <MetalColor14KWhiteGold /> },
  '14KW': { title: '14K White Gold', icon: <MetalColor14KWhiteGold /> },
  '14KWY': {
    title: '14K White & Yellow Gold',
    icon: <MetalColor14KWhiteYellowGold />,
  },
  '14KWR': {
    title: '14K White & Rose Gold',
    icon: <MetalColor14KWhiteRoseGold />,
  },
  '14KR': { title: '14K Rose Gold', icon: <MetalColor14KRoseGold /> },
  '18KY': { title: '18K Yellow Gold', icon: <MetalColor18KYellowGold /> },
  '18KW': { title: '18K White Gold', icon: <MetalColor18KWhiteGold /> },
  '18KR': { title: '18K Rose Gold', icon: <MetalColor18KRoseGold /> },
  '18KWR': {
    title: '18K White & Rose Gold',
    icon: <MetalColor18KWhiteRoseGold />,
  },
  '18KWY': {
    title: '18K White & Yellow Gold',
    icon: <MetalColor18KWhiteYellowGold />,
  },
  '18KWYR': {
    title: '18K White & Yellow & Rose Gold',
    icon: <MetalColor18KWhiteYellowRoseGold />,
  },
  PT900: { title: 'Platinum PT900', icon: <MetalColorPT900 /> },
  PT950: { title: 'Platinum PT950', icon: <MetalColorPT950 /> },
  '18VY': {
    title: '18K Vermeil Yellow Gold',
    icon: <MetalColor18KVermeilYellowGold />,
  },
  '18VR': {
    title: '18K Vermeil Rose Gold',
    icon: <MetalColor18KVermeilRoseGold />,
  },
  '925': {
    title: 'Silver',
    icon: <MetalColor925Silver />,
  },
};

export const EngravingFonts = [
  { label: 'Harrington', value: 'harrington' },
  { label: 'LaurenScriptRegular', value: 'laurenScriptRegular' },
  { label: 'TimesNewRoman', value: 'timesNewRoman' },
  { label: 'Tunga', value: 'tunga' },
  { label: 'MonotypeCorsiva', value: 'monotypeCorsiva' },
];

export type EngravingFontType =
  | 'Harrington'
  | 'LaurenScriptRegular'
  | 'TimesNewRoman'
  | 'Tunga'
  | 'MonotypeCorsiva';
type EngravingFontStylesType = {
  title: string;
  className: string;
  icon: JSX.Element;
};

export const EngravingFontStyles: Record<
  EngravingFontType,
  EngravingFontStylesType
> = {
  Harrington: {
    title: 'Harrington',
    className: 'font-harrington',
    icon: <FontHarringtonIcon className="h-10 w-10 text-gray-400" />,
  },
  LaurenScriptRegular: {
    title: 'LaurenScriptRegular',
    className: 'font-lauren-script',
    icon: <FontLaurenScriptRegularIcon className="h-10 w-10 text-gray-400" />,
  },
  TimesNewRoman: {
    title: 'TimesNewRoman',
    className: 'font-timesnewroman',
    icon: <FontTimesNewRomanIcon className="h-10 w-10 text-gray-400" />,
  },
  Tunga: {
    title: 'Tunga',
    className: 'font-tunga',
    icon: <FontTungaIcon className="h-10 w-10 text-gray-400" />,
  },
  MonotypeCorsiva: {
    title: 'MonotypeCorsiva',
    className: 'font-monotype-corsiva',
    icon: <FontMonotypeCorsivaIcon className="h-10 w-10 text-gray-400" />,
  },
};

export const ProductConfig = {
  image: {
    thumbnail: {
      width: 360,
      height: 360,
      quality: 100,
    },
  },
};

export const SettingStylesRef = {
  'channel-set': 119,
  halo: 122,
  pave: 118,
  'side-stone': 120,
  solitaire: 117,
  vintage: 121,
  setting: 117,
  'three-stone': 5636,
  'dual-pave': 5637,
  'man-engagement-ring': 5638,
  curve: 5639,
  twisted: 5640,
  'hidden-diamond': 5641,
  'hidden-halo': 5642,
  'vow-of-love': 5643,
};

export const WeddingBandMainCategories = {
  women: 88,
  men: 94,
};

export const JewelleryMainCategories = {
  rings: 106,
  necklaces: 27,
  earrings: 28,
  bracelets: 29,
  pendants: 195,
  collections: 167,
};

export const WeddingBandStyles = {
  women: {
    classic: 89,
    diamond: 91,
    eternity: 92,
    'half-eternity': 165,
    'full-eternity': 166,
    alternative: 93,
    plain: 182,
  },
  men: {
    classic: 95,
    diamond: 96,
    alternative: 97,
    plain: 181,
  },
} as any;

export const JewelleryStyles = {
  rings: {
    gemstones: 107,
    diamonds: 108,
    eternity: 109,
    pearls: 110,
    golds: 114,
    dainty: 119,
    plain: 180,
  },
  necklaces: {
    diamonds: 30,
    gemstones: 31,
    pearls: 32,
    golds: 115,
    engravable: 111,
    alphabet: 142,
    chain: 156,
    vermeil: 185,
    'solitaire-diamond-pendants': 179,
  },
  earrings: {
    diamonds: 34,
    gemstones: 35,
    pearls: 36,
    'diamond-studs': 123,
    vermeil: 186,
    gold: 188,
  },
  bracelets: {
    diamonds: 38,
    golds: 116,
    engravable: 113,
    'tennis-bracelets': 176,
    gemstone: 39,
    pearl: 40,
    vermeil: 187,
  },
  chains: {
    chains: 156,
  },
  collections: {
    'si-dian-jin': 168,
    'si-dian-zuan': 169,
    'blooming-illusion': 170,
    'blossom-illusion': 171,
    'vol-jewellery': 183,
    'vermeil-jewellery': 184,
  },
  'care-collectibles': {
    'cleaning-kit': 177,
    box: 178,
  },
  pendants: {
    casing: 196,
  },
};

export const GoldStyles = {
  '999': {
    pendants: 151,
    bars: 152,
    coins: 153,
    chains: 154,
    bracelets: 157,
    charms: 158,
    necklaces: 159,
    bangles: 160,
    earrings: 161,
    rings: 162,
    'baby-bangles': 172,
    'si-dian-jin': 168,
  },
  '916': {
    pendants: 151,
    bars: 152,
    coins: 153,
    chains: 154,
    bracelets: 157,
    charms: 158,
    necklaces: 159,
    bangles: 160,
    earrings: 161,
    rings: 162,
    'baby-bangles': 172,
  },
};

export const MetalColor = {
  190: '14KWY',
  191: '14KWR',
  192: '14KWYR',
  193: '14KYR',
  194: '14KW',
  195: '14KR',
  196: '14KY',
  197: '18KW',
  198: '18KWYR',
  199: '18KYR',
  200: '18KWR',
  201: '18KWY',
  126: '18KR',
  127: '18KY',
  128: 'PT900',
  5635: 'PT950',
  5650: '999 Gold',
  5651: '916 Gold',
  5652: '18VY',
  5653: '18VR',
  5654: '925',
};

export const metalOptions = [
  { value: '14KY', name: '14K Yellow Gold' },
  { value: '14KW', name: '14K White Gold' },
  { value: '14KWY', name: '14K White & Yellow Gold' },
  { value: '14KWR', name: '14K White & Rose Gold' },
  { value: '14KR', name: '14K Rose Gold' },
  { value: '18KY', name: '18K Yellow Gold' },
  { value: '18KW', name: '18K White Gold' },
  { value: '18KR', name: '18K Rose Gold' },
  { value: '18KWR', name: '18K White & Rose Gold' },
  { value: '18KWY', name: '18K White & Yellow Gold' },
  { value: '18KWYR', name: '18K White & Yellow & Rose Gold' },
  { value: 'PT900', name: 'Platinum PT900' },
  { value: '18VY', name: '18K Gold Vermeil' },
  { value: '18VR', name: '18K Rose Vermeil' },
  { value: '925', name: 'Silver' },
  /* { value: 'PT950', name: 'Platinum PT950' }, */
];

export const metalColorNames = {
  'yellow-gold': ['14KY', '18KY'],
  'white-gold': ['14KW', '18KW'],
  'rose-gold': ['14KR', '18KR'],
  'yellow-white-gold': ['14KWY', '18KWY'],
  'white-rose-gold': ['14KWR', '18KWR'],
  'white-yellow-gold': ['14KWY', '18KWY'],
  'white-yellow-rose-gold': ['18KWYR'],
  platinum: ['PT900'],
};

export const metalOptions2 = [
  {
    value: '14k',
    name: '14K Gold',
    icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/wb-14k.png',
  },
  {
    value: '18k',
    name: '18K Gold',
    icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/wb-18k.png',
  },
  {
    value: 'pt',
    name: 'Platinum',
    icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/wb-pt.png',
  },
  {
    value: 'all',
    name: 'All Metals',
    icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/wb-pt.png',
  },
];

export const metalColorOptions = [
  // {
  //   value: 'white',
  //   name: 'White Gold',
  //   icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/white-gold-icon.png',
  // },
  // {
  //   value: 'yellow',
  //   name: 'Yellow Gold',
  //   icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/yellow-gold-icon.png',
  // },
  // {
  //   value: 'rose',
  //   name: 'Rose Gold',
  //   icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/rose-gold-icon.png',
  // },
  {
    value: '14kw',
    name: '14K White Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14kw.svg',
  },
  {
    value: '14ky',
    name: '14K Yellow Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14ky.svg',
  },
  {
    value: '14kr',
    name: '14K Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14kr.svg',
  },
  {
    value: '14kwr',
    name: '14K White & Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14kwr.svg',
  },
  {
    value: '14kwy',
    name: '14K White & Yellow Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14kwy.svg',
  },
  {
    value: '14kyr',
    name: '14K Yellow Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-14kyr.svg',
  },
  {
    value: '18kw',
    name: '18K White Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kw.svg',
  },
  {
    value: '18ky',
    name: '18K Yellow Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18ky.svg',
  },
  {
    value: '18kr',
    name: '18K Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kr.svg',
  },
  {
    value: '18kwr',
    name: '18K White & Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kwr.svg',
  },
  {
    value: '18kwy',
    name: '18K White & Yellow Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kwy.svg',
  },
  {
    value: '18kyr',
    name: '18K Yellow Rose Gold',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kyr.svg',
  },
  {
    value: 'pt900',
    name: 'Platinum PT900',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-pt.svg',
  },
  {
    value: '18VY',
    name: '18K Gold Vermeil',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kvy.svg',
  },
  {
    value: '18VR',
    name: '18K Rose Vermeil',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-18kvr.svg',
  },
  {
    value: '925',
    name: '925 Silver',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-925.svg',
  },
  /* {
    value: 'pt900',
    name: 'Platinum PT900',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-pt.svg',
  }, */
  /* {
    value: 'pt950',
    name: 'Platinum PT950',
    icon: 'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/metal-pt.svg',
  }, */
  // {
  //   value: 'all',
  //   name: 'All Colors',
  //   icon: 'https://d213t4exkgxsjh.cloudfront.net/assets/icons/rose-gold-icon.png',
  // },
];

export const sortOptions = [
  { name: 'text-lowest-price', value: 'price-asc' },
  { name: 'text-highest-price', value: 'price-desc' },
  { name: 'Name: Ascending', value: 'name-asc' },
  { name: 'Name: Descending', value: 'name-desc' },
];

export const settingStyles = [
  {
    category_id: '117',
    title: 'Solitaire',
    alt: 'Solitaire diamond ring in a classic four-prong setting',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-solitaire.jpg',
    url: 'engagement-rings/solitaire',
  },
  {
    category_id: '118',
    title: 'Pave',
    alt: 'Pavé-set diamond ring with tiny stones along the band',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-pave.jpg',
    url: 'engagement-rings/pave',
  },
  {
    category_id: '119',
    title: 'Channel-set',
    alt: 'Channel-set diamond ring with a sleek, modern look',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-channelset.jpg',
    url: 'engagement-rings/channel-set',
  },
  {
    category_id: '120',
    title: 'Side-stone',
    alt: 'Side-stone engagement ring with accent diamonds on a white gold band',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-sidestone.jpg',
    url: 'engagement-rings/side-stone',
  },
  {
    category_id: '121',
    title: 'Vintage',
    alt: 'Vintage-style engagement ring with intricate filigree details',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-vintage.jpg',
    url: 'engagement-rings/vintage',
  },
  {
    category_id: '122',
    title: 'Halo',
    alt: 'Halo engagement ring with a central diamond and surrounding stones',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-halo.jpg',
    url: 'engagement-rings/halo',
  },
  {
    category_id: '5636',
    title: '3 Stone',
    alt: 'Three-stone ring with a trio of round-cut diamonds',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-three-stone.jpg',
    url: 'engagement-rings/three-stone',
  },
  {
    category_id: '5637',
    title: 'Dual Pave',
    alt: 'Dual pavé engagement ring with two rows of micro-diamonds',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-dual-pave.jpg',
    url: 'engagement-rings/dual-pave',
  },
  {
    category_id: '5638',
    title: 'Man Engagement Ring',
    alt: "Men's engagement ring with a bold round diamond in a matte-finish band",
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-man-engagement-ring.jpg',
    url: 'engagement-rings/man-engagement-ring',
  },
  {
    category_id: '5639',
    title: 'Curve',
    alt: 'Curved engagement ring with a contoured band and center diamond',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-curve.jpg',
    url: 'engagement-rings/curve',
  },
  {
    category_id: '5640',
    title: 'Twisted',
    alt: 'Twisted-band engagement ring with a single brilliant diamond',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-twisted.jpg',
    url: 'engagement-rings/twisted',
  },
  {
    category_id: '5641',
    title: 'Hidden Diamond',
    alt: 'Ring featuring a hidden diamond set inside the band',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-hidden-diamond.jpg',
    url: 'engagement-rings/hidden-diamond',
  },
  {
    category_id: '5642',
    title: 'Hidden Halo',
    alt: 'Hidden halo engagement ring with diamonds under the center stone',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-hidden-halo.jpg',
    url: 'engagement-rings/hidden-halo',
  },
  {
    category_id: '5643',
    title: 'Vow of Love Collection',
    alt: 'Vow of Love Collection ring with intertwined gold and diamond elements',
    image:
      'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/setting-collection-signature.jpg',
    url: 'engagement-rings/vow-of-love',
  },
];

export const weddingBandWomenRef = [
  {
    category_id: '89',
    title: 'Classic',
    alt: 'Classic plain wedding band in polished white gold',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0001-3mm-18ky.jpg',
    url: 'wedding-bands/women/classic',
  },
  {
    category_id: '91',
    title: 'Diamond',
    alt: 'Diamond-studded wedding band with a single row of round-cut stones',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0353b-18kr.jpg',
    url: 'wedding-bands/women/diamond',
  },
  // {
  //   category_id: '92',
  //   title: 'Eternity',
  //   image:
  //     'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/nr0001-lhe040-14ky.jpg',
  // },
  // {
  //   category_id: '165',
  //   title: 'Half Eternity',
  //   alt: 'Half eternity wedding band with diamonds along the top in yellow gold',
  //   image:
  //     'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/nr0001-lhe070-14kw.jpg',
  //   url: 'wedding-bands/women/half-eternity',
  // },
  // {
  //   category_id: '166',
  //   title: 'Full Eternity',
  //   alt: 'Full eternity wedding band with diamonds encircling a platinum band',
  //   image:
  //     'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/nr0001-lfe090-14kw.jpg',
  //   url: 'wedding-bands/women/full-eternity',
  // },
  {
    category_id: '93',
    title: 'Alternative',
    alt: 'Alternative wedding band with a matte black finish and geometric design',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0097b-18kw.jpg',
    url: 'wedding-bands/women/alternative',
  },
] as TStyles[];

export const weddingBandMenRef = [
  {
    category_id: '95',
    title: 'Classic',
    alt: 'Classic plain wedding band in polished white gold',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0001-3mm-18ky.jpg',
    url: 'wedding-bands/men/classic',
  },
  {
    category_id: '96',
    title: 'Diamond',
    alt: 'Diamond-studded wedding band with a single row of round-cut stones',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0353b-18kr.jpg',
    url: 'wedding-bands/men/diamond',
  },
  {
    category_id: '97',
    title: 'Alternative',
    alt: 'Alternative wedding band with a matte black finish and geometric design',
    image:
      'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/nr0001-lhe040-14ky.jpg',
    url: 'wedding-bands/men/alternative',
  },
] as TStyles[];

export const weddingBandRef = {
  women: weddingBandWomenRef,
  men: weddingBandMenRef,
};

export const filterWeddingBandRef = {
  women: 88,
  men: 94,
};

/**
 * Jewellery
 */
export const jewelleryStyles = {
  rings: [
    {
      category_id: '107',
      title: 'Gemstones',
      alt: 'Gemstones',
      url: 'jewellery/rings/gemstones',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/r/dr6650c-18kw_2.jpg',
    },
    {
      category_id: '108',
      title: 'Diamonds',
      alt: 'Diamonds',
      url: 'jewellery/rings/diamonds',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/n/r/nr0019-2-18ky_2.jpg',
    },
    {
      category_id: '109',
      title: 'Eternity',
      alt: 'Eternity',
      url: 'jewellery/rings/eternity',
      image:
        'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/nr0001-lhe040-14ky.jpg',
    },
    {
      category_id: '110',
      title: 'Pearls',
      alt: 'Pearls',
      url: 'jewellery/rings/pearls',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/s/d/sdr0134-18kw_1.jpg',
    },
    {
      category_id: '114',
      title: 'Golds',
      alt: 'Golds',
      url: 'jewellery/rings/golds',
      image:
        'https://d213t4exkgxsjh.cloudfront.net/catalog/product/5/wr0132b-18kw.jpg',
    },
    {
      category_id: '119',
      title: 'Dainty',
      alt: 'Dainty',
      url: 'jewellery/rings/dainty',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhr1259_18kr_2.jpg',
    },
  ],
  necklaces: [
    {
      category_id: '30',
      title: 'Diamonds',
      alt: 'Diamonds',
      url: 'jewellery/necklaces/diamonds',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhpi248-0.5ct.jpg',
    },
    {
      category_id: '31',
      title: 'Gemstones',
      alt: 'Gemstones',
      url: 'jewellery/necklaces/gemstones',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhp1069-14kw_1.jpg',
    },
    {
      category_id: '32',
      title: 'Pearls',
      alt: 'Pearls',
      url: 'jewellery/necklaces/pearls',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/p/dp1724-s11-18kw-02.jpg',
    },
    {
      category_id: '115',
      title: 'Golds',
      alt: 'Golds',
      url: 'jewellery/necklaces/golds',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/m/9/m9179-gp-14ky_1.jpg',
    },
    {
      category_id: '111',
      title: 'Engravable',
      alt: 'Engravable',
      url: 'jewellery/necklaces/engravable',
      image: 'https://media.michaeltrio.com/catalog/product/f/r/frame_29.png',
    },
    {
      category_id: '142',
      title: 'Alphabet',
      alt: 'Alphabet',
      url: 'jewellery/necklaces/alphabet',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/m/d/mdp0076m-1-18kw_1.jpg',
    },
    {
      category_id: '156',
      title: 'Chain',
      alt: 'Chain',
      url: 'jewellery/necklaces/chain',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/0/-/0-8BH-18KY_1_1.jpg',
    },
    {
      category_id: '185',
      title: 'Vermeil',
      alt: 'Vermeil',
      url: 'jewellery/necklaces/vermeil',
      image:
        'https://shop.michaeltrio.com/media/catalog/product/z/1/z1019-n-925_cat1_copy.jpg',
    },
  ],

  earrings: [
    {
      category_id: '34',
      title: 'Diamonds',
      alt: 'Diamonds',
      url: 'jewellery/earrings/diamonds',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhe1260-4mm-1.jpg',
    },
    {
      category_id: '35',
      title: 'Gemstones',
      alt: 'Gemstones',
      url: 'jewellery/earrings/gemstones',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/e/de0755a-sa-18kw_1.jpeg',
    },
    {
      category_id: '36',
      title: 'Pearls',
      alt: 'Pearls',
      url: 'jewellery/earrings/pearls',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/a/7/a7m.jpg',
    },
    // {
    //   category_id: '123',
    //   title: 'Diamond Studs',
    //   url: 'jewellery/earrings/diamond-studs',
    //   image:
    //     'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/r/o/round_i-2.0ct.jpg',
    // },
    {
      category_id: '186',
      title: 'Vermeil',
      alt: 'Vermeil',
      url: 'jewellery/earrings/vermeil',
      image:
        'https://media.michaeltrio.com/catalog/product/z/3/z300-e-5-925_cat1_copy.jpg',
    },
    {
      category_id: '188',
      title: 'Gold',
      alt: 'Gold',
      url: 'jewellery/earrings/gold',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/095014b155d73d21812e9ddaad0ce8d9/d/h/dhe1259-14kw.jpg',
    },
  ],
  bracelets: [
    {
      category_id: '38',
      title: 'Diamonds',
      alt: 'Jewllery Bracelets Diamonds',
      url: 'jewellery/bracelets/diamonds',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/b/db0083-18kw_1.jpeg',
    },
    {
      category_id: '116',
      title: 'Golds',
      alt: 'Golds',
      url: 'jewellery/bracelets/golds',
      image:
        'https://media.michaeltrio.com/catalog/product/d/h/dhb1259-14kr.jpg',
    },
    {
      category_id: '113',
      title: 'Engravable',
      alt: 'Engravable',
      url: 'jewellery/bracelets/engravable',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/r/d/rdinf-gbr-14ky.jpg',
    },
    // {
    //   category_id: '176',
    //   title: 'Tennis Bracelets',
    //   url: 'jewellery/bracelets/tennis-bracelets',
    //   image:
    //     'https://media.michaeltrio.com/catalog/product/d/b/db0113-l17500-14kw.jpg',
    // },
    {
      category_id: '187',
      title: 'Vermeil',
      alt: 'Vermeil',
      url: 'jewellery/bracelets/vermeil',
      image:
        'https://shop.michaeltrio.com/media/catalog/product/z/2/z268-br-925_cat1_copy.jpg',
    },
  ],
  'shop-all': [
    {
      category_id: '106',
      title: 'Rings',
      alt: 'Rings',
      url: 'jewellery/rings',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/r/dr6650c-18kw_2.jpg',
    },
    {
      category_id: '28',
      title: 'Earrings',
      alt: 'Earrings',
      url: 'jewellery/earrings',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhe1260-4mm-1.jpg',
    },
    {
      category_id: '27',
      title: 'Necklaces',
      alt: 'Necklaces',
      url: 'jewellery/necklaces',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/h/dhpi248-0.5ct.jpg',
    },
    {
      category_id: '29',
      title: 'Bracelets & Bangles',
      alt: 'Bracelets & Bangles',
      url: 'jewellery/bracelets',
      image:
        'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/d/b/db0083-18kw_1.jpeg',
    },
    {
      category_id: '',
      title: 'Chains',
      alt: 'Chains',
      url: 'jewellery/chains',
      image:
        'https://media.michaeltrio.com/catalog/product/1/7/17KBLR121D-18KW_1_1.jpg',
    },
  ],

  collections: [
    {
      category_id: 170,
      title: 'Blooming Illusions',
      alt: 'Blooming Illusions',
      url: 'jewellery/collections/blooming-illusion',
      image:
        'https://media.michaeltrio.com/catalog/product/d/h/dhpi248-0.3ct_1.jpg',
    },
    {
      category_id: 171,
      title: 'Blossom Illusions',
      alt: 'Blossom Illusions',
      url: 'jewellery/collections/blossom-illusion',
      image:
        'https://media.michaeltrio.com/catalog/product/d/h/dhp1260-5mm-1.jpg',
    },
    {
      category_id: 123,
      title: 'Diamond Studs',
      alt: 'Diamond Studs',
      url: 'jewellery/collections/diamond-studs',
      image:
        'https://media.michaeltrio.com/catalog/product/p/r/princess_g-0.25ct__1.jpg',
    },
    {
      category_id: 166,
      title: 'Full Eternity Rings',
      alt: 'Full Eternity Rings',
      url: 'jewellery/collections/full-eternity',
      image:
        'https://media.michaeltrio.com/catalog/product/n/r/nr0002-lfe050-14kr_cat2.png',
    },
    {
      category_id: 165,
      title: 'Half Eternity Rings',
      alt: 'Half Eternity Rings',
      url: 'jewellery/collections/half-eternity',
      image:
        'https://media.michaeltrio.com/catalog/product/N/R/NR0001-LHE030-14KW_2_2.png',
    },
    {
      category_id: 179,
      title: 'Solitaire Diamonds',
      alt: 'Solitaire Diamonds',
      url: 'jewellery/collections/solitaire-diamonds',
      image:
        'https://media.michaeltrio.com/catalog/product/m/d/mdp0001-030_whitegold-position1_3.png',
    },
    {
      category_id: 176,
      title: 'Tennis Bracelets',
      alt: 'Tennis Bracelets',
      url: 'jewellery/collections/tennis-bracelets',
      image:
        'https://media.michaeltrio.com/catalog/product/d/b/db0111-17cm1ct_whitegold-position1_2_2.png',
    },
    {
      category_id: 184,
      title: 'Vermeil Jewellery',
      alt: 'Vermeil Jewellery',
      url: 'jewellery/collections/vermeil-jewellery',
      image:
        'https://media.michaeltrio.com/catalog/product/z/2/z268br-925_cat1_copy.jpg',
    },
    {
      category_id: 183,
      title: 'Vow of Love Jewellery',
      alt: 'Vow of Love Jewellery',
      url: 'jewellery/collections/vow-of-love-jewellery',
      image:
        'https://media.michaeltrio.com/catalog/product/d/h/dhp1298-7mm_yellowgold-position1.png',
    },
  ],
};

export const jewellerySubStylesRef = [
  {
    category_id: '142',
    title: 'Alphabet',
    image: '',
  },
  {
    category_id: '156',
    title: 'Chain',
    image: '',
  },
  {
    category_id: '119',
    title: 'Dainty',
    image: '',
  },
  {
    category_id: '30,34,108',
    title: 'Diamond',
    image: '',
  },
  {
    category_id: '123',
    title: 'Diamond Stud',
    image: '',
  },
  {
    category_id: '109',
    title: 'Eternity',
    image: '',
  },
  {
    category_id: '31,35,107',
    title: 'Gemstone',
    image: '',
  },
  {
    category_id: '116,114',
    title: 'Gold',
    image: '',
  },
  {
    category_id: '32,36,40,110',
    title: 'Pearl',
    image: '',
  },
];

export const jewelleryCollectionRef = [
  {
    category_id: '168',
    title: 'Si Dian Jin 四点金',
    image: '',
  },
  {
    category_id: '169',
    title: 'Si Dian Zuan 四点钻',
    image: '',
  },
  {
    category_id: '170',
    title: 'Blooming Illusion',
    image: '',
  },
  {
    category_id: '171',
    title: 'Blossom Illusion',
    image: '',
  },
  {
    category_id: '111,113',
    title: 'Engravable',
    image: '',
  },
  {
    category_id: '184',
    title: 'Vermeil',
    image: '',
  },
] as TStyles[];

export const CollectionStyles = {
  'vow-of-love-jewellery': [
    {
      category_id: '27',
      title: 'necklaces',
    },
    {
      category_id: '29',
      title: 'bracelets',
    },
    {
      category_id: '28',
      title: 'earrings',
    },
  ],
  'vermeil-jewellery': [
    {
      category_id: '27',
      title: 'necklaces',
    },
    {
      category_id: '29',
      title: 'bracelets',
    },
    {
      category_id: '28',
      title: 'earrings',
    },
  ],
  'blooming-illusion': [
    {
      category_id: '27',
      title: 'necklaces',
    },
    {
      category_id: '29',
      title: 'bracelets',
    },
    {
      category_id: '28',
      title: 'earrings',
    },
    {
      category_id: '106',
      title: 'rings',
    },
  ],
  'blossom-illusion': [
    {
      category_id: '27',
      title: 'necklaces',
    },
    {
      category_id: '29',
      title: 'bracelets',
    },
    {
      category_id: '28',
      title: 'earrings',
    },
    {
      category_id: '106',
      title: 'rings',
    },
  ],
};

export const classicCollectionRef = [
  {
    category_id: '165',
    title: 'Half Eternity Rings',
    url: 'jewellery/collections/half-eternity',
    image:
      'https://shop.michaeltrio.com/media/catalog/product/N/R/NR0001-LHE030-14KR_2_2.png',
  },
  {
    category_id: '166',
    title: 'Full Eternity Rings',
    url: 'jewellery/collections/full-eternity',
    image:
      'https://shop.michaeltrio.com/media/catalog/product/N/R/NR0001-LFE050-14KW_2_2.png',
  },
  {
    category_id: '123',
    title: 'Diamond Stud',
    url: 'jewellery/collections/diamond-studs',
    image:
      'https://shop.michaeltrio.com/media/catalog/product/m/d/mde0006-025_whitegold-position1_1.png',
  },
  {
    category_id: '179',
    title: 'Diamond Pendants',
    url: 'jewellery/collections/solitaire-diamonds',
    image:
      'https://shop.michaeltrio.com/media/catalog/product/m/d/mdp0001-030_yellowgold-position1_1.png',
  },
  {
    category_id: '176',
    title: 'Tennis Bracelets',
    url: 'jewellery/collections/tennis-bracelets',
    image:
      'https://shop.michaeltrio.com/media/catalog/product/d/b/db0111-17cm5ct_rosegold-position3_1.png',
  },
];

export const goldRef = [
  {
    category_id: '152',
    title: 'Bars',
    alt: 'Bars',
    url: 'golds/bars',
    image:
      'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/H/W/HW0014-20-999_1_1.jpg',
  },
  {
    category_id: '154',
    title: 'Chains',
    alt: 'Chains',
    url: 'golds/chains',
    image:
      'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/H/W/HWC0039-999_1_4.jpg',
  },
  {
    category_id: '153',
    title: 'Coins',
    alt: 'Coins',
    url: 'golds/coins',
    image:
      'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/H/W/HW0002-02-999_1_1.jpg',
  },
  {
    category_id: '151',
    title: 'Pendants',
    alt: 'Pendants',
    url: 'golds/pendants',
    image:
      'https://media.michaeltrio.com/catalog/product/cache/b414f9240e0ad426520e329fc732fd8a/H/W/HWP0030M-999_1.jpg',
  },
  {
    category_id: '157',
    title: 'Bracelets',
    url: 'golds/bracelets',
    image:
      'https://media.michaeltrio.com/catalog/product/F/B/FBR0002-5G-999_1.jpg',
  },
  {
    category_id: '158',
    title: 'Charms',
    url: 'golds/charms',
    image:
      'https://media.michaeltrio.com/catalog/product/F/C/FCM0002-999_1.jpg',
  },
  {
    category_id: '159',
    title: 'Necklaces',
    url: 'golds/necklaces',
    image:
      'https://media.michaeltrio.com/catalog/product/f/n/fn0031-5g-999_1.jpg',
  },
  {
    category_id: '160',
    title: 'Bangles',
    url: 'golds/bangles',
    image:
      'https://media.michaeltrio.com/catalog/product/f/b/fbg0002-5g-999_cat1.jpg',
  },
  {
    category_id: '161',
    title: 'Earrings',
    url: 'golds/earrings',
    image:
      'https://media.michaeltrio.com/catalog/product/f/e/fe0009s-5g-999_cat1.jpg',
  },
  {
    category_id: '162',
    title: 'Rings',
    url: 'golds/rings',
    image: 'https://media.michaeltrio.com/catalog/product/b/k/bk_1.jpg',
  },
  {
    category_id: '172',
    title: 'Baby Bangles',
    url: 'golds/baby-bangles',
    image:
      'https://media.michaeltrio.com/catalog/product/f/b/fbg0006b-5g-999_cat1.jpg',
  },
  {
    category_id: '168',
    title: 'Si Dian Jin',
    url: 'golds/si-dian-jin',
    image:
      'https://media.michaeltrio.com/catalog/product/f/e/fe0018s-5g-999_cat1.jpg',
  },
] as TStyles[];

export const goldMeterialRef = [
  {
    category_id: '5650',
    title: '999 Gold',
    url: 'golds/999-gold',
    image: '',
  },
  {
    category_id: '5651',
    title: '916 Gold',
    url: 'golds/916-gold',
    image: '',
  },
] as TStyles[];

export const filterJewelleryRef = {
  necklaces: 27,
  earrings: 28,
  bracelets: 29,
  rings: 106,
};

export const filterGoldRef = {
  pendants: 151,
  bars: 152,
  coins: 153,
  chains: 154,
};

type TFilterMetal = {
  [key: string]: number[];
};

export const filterMetalColorRef: TFilterMetal = {
  white: [190, 191, 192, 194, 197, 198, 200, 201],
  yellow: [190, 193, 196, 198, 199, 201, 127],
  rose: [191, 192, 193, 195, 198, 199, 200, 126],
};

export const filterMetalRef: TFilterMetal = {
  '14k': [190, 191, 192, 193, 194, 195, 196],
  '18k': [197, 198, 199, 200, 201, 126, 127],
  pt: [128],
};

type TProductUrlPath = {
  [key: string]: {
    [key: number]: string;
  };
};

type TMainCategories = {
  [key: number]: string;
};

export const mainCategories: TMainCategories = {
  4: 'engagement-rings',
  5: 'wedding-bands',
  88: 'women',
  94: 'men',
  6: 'jewellery',
  27: 'necklaces',
  28: 'earrings',
  29: 'bracelets',
  106: 'rings',
  150: 'golds',
} as const;

export const MainCategoriesNames = {
  ENGAGEMENT_RINGS: 4,
  WEDDING_BANDS: 5,
  JEWELLERY: 6,
  GOLDS: 150,
};

export const productUrlPath: TProductUrlPath = {
  'engagement-rings': {
    119: 'channel-set',
    122: 'halo',
    118: 'pave',
    120: 'side-stone',
    117: 'solitaire',
    121: 'vintage',
  },
  'wedding-bands': {
    89: 'classic',
    91: 'diamond',
    92: 'eternity',
    93: 'alternative',
    95: 'classic',
    96: 'diamond',
    97: 'alternative',
    181: 'plain',
    182: 'plain',
  },
  jewellery: {
    30: 'diamonds',
    31: 'gemstones',
    32: 'pearls',
    111: 'engravables',
    115: 'golds',
    142: 'alphabet',
    156: 'chains',
    34: 'diamonds',
    35: 'gemstones',
    36: 'pearls',
    123: 'diamond-studs',
    38: 'diamonds',
    39: 'gemstones',
    40: 'pearls',
    113: 'engravables',
    116: 'golds',
    176: 'tennis-bracelets',
    107: 'gemstones',
    108: 'diamonds',
    109: 'eternitys',
    110: 'pearls',
    114: 'golds',
    119: 'dainty',
    130: 'color-diamond',
    180: 'plain',
    188: 'golds',
    179: 'solitaire-diamond-pendants',
    196: 'casing',
  },
  golds: {
    151: 'gold-pendant',
    152: 'gold-bar',
    153: 'gold-coin',
    154: 'gold-chain',
  },
  'custom-items': {
    193: 'engraving',
  },
} as const;


export const SimplifiedCategories = {
  4: {
    119: 'engagement-rings/channel-set',
    122: 'engagement-rings/halo',
    118: 'engagement-rings/pave',
    120: 'engagement-rings/side-stone',
    117: 'engagement-rings/solitaire',
    121: 'engagement-rings/vintage',
    5636: 'engagement-rings/three-stone',
    5637: 'engagement-rings/dual-pave',
    5638: 'engagement-rings/man-engagement-ring',
    5639: 'engagement-rings/curve',
    5640: 'engagement-rings/twisted',
    5641: 'engagement-rings/hidden-diamond',
    5642: 'engagement-rings/hidden-halo',
    5643: 'engagement-rings/vow-of-love',
  },

  5: {
    88: {
      89: 'wedding-bands/women/classic',
      91: 'wedding-bands/women/diamond',
      92: 'wedding-bands/women/eternity',
      93: 'wedding-bands/women/alternative',
      165: 'wedding-bands/women/half-eternity',
      166: 'wedding-bands/women/full-eternity',
      182: 'wedding-bands/women/plain',
    },
    94: {
      95: 'wedding-bands/men/classic',
      96: 'wedding-bands/men/diamond',
      97: 'wedding-bands/men/alternative',
      181: 'wedding-bands/men/plain',
    },
  },
  6: {
    27: {
      30: 'jewellery/necklaces/diamonds',
      31: 'jewellery/necklaces/gemstones',
      32: 'jewellery/necklaces/pearls',
      115: 'jewellery/necklaces/golds',
      111: 'jewellery/necklaces/engravables',
      142: 'jewellery/necklaces/alphabet',
      156: 'jewellery/necklaces/chains',
      185: 'jewellery/necklaces/vermeil',
      179: 'jewellery/necklaces/solitaire-diamond-pendants',
    },
    28: {
      34: 'jewellery/earrings/diamond',
      35: 'jewellery/earrings/gemstone',
      36: 'jewellery/earrings/pearls',
      123: 'jewellery/earrings/diamond-stud',
      186: 'jewellery/earrings/vermeil',
      188: 'jewellery/earrings/golds',
    },
    29: {
      38: 'jewellery/bracelets/diamond',
      39: 'jewellery/bracelets/gemstone',
      40: 'jewellery/bracelets/pearls',
      113: 'jewellery/bracelets/engravable',
      116: 'jewellery/bracelets/gold',
      176: 'jewellery/bracelets/tennis-bracelets',
      187: 'jewellery/bracelets/vermeil',
    },
    106: {
      107: 'jewellery/rings/gemstone',
      108: 'jewellery/rings/diamond',
      109: 'jewellery/rings/eternity',
      110: 'jewellery/rings/pearls',
      114: 'jewellery/rings/gold',
      119: 'jewellery/rings/dainty',
      130: 'jewellery/rings/color-diamond',
      180: 'jewellery/rings/plain',
    },
    167: {
      168: 'jewellery/collections/si-dian-jin',
      169: 'jewellery/collections/si-dian-zuan',
      170: 'jewellery/collections/blooming-illusion',
      171: 'jewellery/collections/blossom-illusion',
      183: 'jewellery/collections/vol-jewellery',
      184: 'jewellery/collections/vermeil-jewellery',
    },
    175: {
      177: 'jewellery/care-collectibles/cleaning-kit',
      178: 'jewellery/care-collectibles/box',
    },
    195: {
      196: 'jewellery/pendants/casing',
    },
  },
  150: {
    151: 'golds/pendants',
    152: 'golds/bars',
    153: 'golds/coins',
    154: 'golds/chains',
    157: 'golds/bracelets',
    158: 'golds/charms',
    159: 'golds/necklaces',
    160: 'golds/bangles',
    161: 'golds/earrings',
    162: 'golds/rings',
    172: 'golds/baby-bangles',
    168: 'golds/si-dian-jin',
    5650: 'golds/999-gold',
    5651: 'golds/916-gold',
  },
  124: {
    193: 'custom-items/engraving',
  },
};

export type TShape = {
  id: string;
  title: string;
  url?: string;
  image: string;
  active?: boolean;
  alt?: string;
};

/**
 * Loose Diamonds
 * Loose Diamonds
 */

export const looseDiamondsRef = {
  shape: [
    {
      id: 'rb',
      title: 'Round',
      alt: 'Round Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/round-diamond.jpg',
    },
    {
      id: 'pr',
      title: 'Princess',
      alt: 'Princess Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/princess-diamond.jpg',
    },
    {
      id: 'em',
      title: 'Emerald',
      alt: 'Emerald Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/emerald-diamond.jpg',
    },
    {
      id: 'as',
      title: 'Asscher',
      alt: 'Asscher Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/asscher-diamond.jpg',
    },
    {
      id: 'mq',
      title: 'Marquise',
      alt: 'Marquise Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/marquise-diamond.jpg',
    },
    {
      id: 'cu',
      title: 'Cushion',
      alt: 'Cushion Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/cushion-diamond.jpg',
    },
    {
      id: 'ra',
      title: 'Radiant',
      alt: 'Radiant Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/radiant-diamond.jpg',
    },
    {
      id: 'pe',
      title: 'Pear',
      alt: 'Pear Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/pear-diamond.jpg',
    },
    {
      id: 'ov',
      title: 'Oval',
      alt: 'Oval Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/oval-diamond.jpg',
    },
    {
      id: 'hs',
      title: 'Heart',
      alt: 'Heart Diamond',
      image:
        'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/heart-diamond.jpg',
    },
  ] as TShape[],
  color: [
    {
      id: 'K',
      title: 'K',
    },
    {
      id: 'I',
      title: 'J',
    },
    {
      id: 'I',
      title: 'K',
    },
    {
      id: 'H',
      title: 'H',
    },
    {
      id: 'G',
      title: 'G',
    },
    {
      id: 'F',
      title: 'F',
    },
    {
      id: 'E',
      title: 'E',
    },
    {
      id: 'D',
      title: 'D',
    },
  ],
  clarity: [
    {
      id: 'SI2',
      title: 'SI2',
    },
    {
      id: 'SI1',
      title: 'SI1',
    },
    {
      id: 'VS2',
      title: 'VS2',
    },
    {
      id: 'VS1',
      title: 'VS1',
    },
    {
      id: 'VVS2',
      title: 'VVS2',
    },
    {
      id: 'VVS1',
      title: 'VVS1',
    },
    {
      id: 'IF',
      title: 'IF',
    },
    {
      id: 'FL',
      title: 'FL',
    },
  ],
  polish: [
    {
      id: 'F',
      title: 'Fair',
    },
    {
      id: 'G',
      title: 'Good',
    },
    {
      id: 'VG',
      title: 'Very Good',
    },
    {
      id: 'EX',
      title: 'Excellent',
    },
  ],
  symmetry: [
    {
      id: 'F',
      title: 'Fair',
    },
    {
      id: 'G',
      title: 'Good',
    },
    {
      id: 'VG',
      title: 'Very Good',
    },
    {
      id: 'EX',
      title: 'Excellent',
    },
  ],
  fluorescence: [
    {
      id: 'vst',
      title: 'Very Strong',
    },
    {
      id: 'stg',
      title: 'Strong',
    },
    {
      id: 'med',
      title: 'Medium',
    },
    {
      id: 'fnt',
      title: 'Faint',
    },
    {
      id: 'non',
      title: 'None',
    },
  ],
  intensity: [
    {
      id: 'fnt',
      title: 'Faint',
    },
    {
      id: 'vlg',
      title: 'Very Light',
    },
    {
      id: 'lgt',
      title: 'Light',
    },
    {
      id: 'fcy',
      title: 'Fancy',
    },
    {
      id: 'ite',
      title: 'Intense',
    },
    {
      id: 'vvd',
      title: 'Vivid',
    },
    {
      id: 'dep',
      title: 'Deep',
    },
    {
      id: 'drk',
      title: 'Dark',
    },
  ],
  natural: [
    {
      cut: [
        {
          id: 'gd',
          title: 'Good',
        },
        {
          id: 'vg',
          title: 'Very Good',
        },
        {
          id: 'id',
          title: 'Ideal',
        },
        {
          id: 'ph',
          title: 'Pure Hearts',
        },
      ],
    },
  ],
  labgrown: [
    {
      cut: [
        {
          id: 'vg',
          title: 'Good',
        },
        {
          id: 'ex',
          title: 'Very Good',
        },
        {
          id: 'id',
          title: 'Ideal',
        },
        {
          id: 'ph',
          title: 'Pure Hearts',
        },
      ],
    },
  ],
};

export const ColourDiamondsRef = {
  color: [
    {
      id: 'yellow',
      title: 'Yellow',
      alt: 'Yellow diamond with a warm golden hue',
      image: '/assets/images/jewel/feed/diamond/yellow-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=yellow',
    },
    {
      id: 'pink',
      title: 'Pink',
      alt: 'Pink diamond with a soft rosy tint in a close-up view',
      image: '/assets/images/jewel/feed/diamond/pink-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=pink',
    },
    {
      id: 'purple',
      title: 'Purple',
      alt: 'Purple diamond with a rich vibrant color',
      image: '/assets/images/jewel/feed/diamond/purple-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=purple',
    },
    {
      id: 'red',
      title: 'Red',
      alt: 'Red diamond with a deep striking hue',
      image: '/assets/images/jewel/feed/diamond/red-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=red',
    },
    {
      id: 'blue',
      title: 'Blue',
      alt: 'Blue diamond with a cool icy tone on a white backdrop',
      image: '/assets/images/jewel/feed/diamond/blue-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=blue',
    },
    {
      id: 'green',
      title: 'Green',
      alt: 'Green diamond with a vivid emerald-like shade',
      image: '/assets/images/jewel/feed/diamond/green-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=green',
    },
    {
      id: 'orange',
      title: 'Orange',
      alt: 'Orange Diamond',
      image: '/assets/images/jewel/feed/diamond/orange-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=orange',
    },
    {
      id: 'brown',
      title: 'Brown',
      alt: 'Brown diamond with a warm earthy tone',
      image: '/assets/images/jewel/feed/diamond/brown-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=brown',
    },
    {
      id: 'gray',
      title: 'Gray',
      alt: 'Gray diamond with a subtle metallic sheen',
      image: '/assets/images/jewel/feed/diamond/grey-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=gray',
    },
    {
      id: 'black',
      title: 'Black',
      alt: 'Black diamond with a bold opaque finish',
      image: '/assets/images/jewel/feed/diamond/black-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=black',
    },
    /* {
      id: 'pepper',
      title: 'Salt & Pepper',
      image: '/assets/images/jewel/feed/diamond/pepper-diamond.png',
      url: 'loose-diamonds/fancy-colour-diamonds/earth-natural-diamonds?color=pepper',
    }, */
  ],
};

export const gemstonesRef = {
  type: [
    {
      id: 'alexandrite',
      title: 'Alexandrite',
      alt: 'Alexandrite gemstone with a color-changing hue from green to purple',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-alexandrite.png',
      url: 'gemstones/alexandrite',
    },
    {
      id: 'amethyst',
      title: 'Amethyst',
      alt: 'Amethyst gemstone with a deep purple sparkle',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-amethyst.png',
      url: 'gemstones/amethyst',
    },
    {
      id: 'aquamarine',
      title: 'Aquamarine',
      alt: 'Aquamarine gemstone with a light blue sheen',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-aquamarine.png',
      url: 'gemstones/alexandrite',
    },
    {
      id: 'color-change-sapphire',
      title: 'Color Change Sapphire',
      alt: 'Color-change sapphire shifting from blue to purple in different light',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-color-change-sapphire.png',
      url: 'gemstones/color-change-sapphire',
    },
    {
      id: 'demantoid',
      title: 'Demantoid',
      alt: 'Demantoid garnet with a vivid green shine and fiery dispersion',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-demantoid.png',
      url: 'gemstones/demantoid',
    },
    {
      id: 'emerald',
      title: 'Emerald',
      alt: 'Emerald gemstone with a rich green color and natural inclusions',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-emerald.png',
      url: 'gemstones/emerald',
    },
    {
      id: 'morganite',
      title: 'Morganite',
      alt: 'Morganite gemstone with a soft peach-pink hue',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-morganite.png',
      url: 'gemstones/morganite',
    },
    {
      id: 'rhodolite',
      title: 'Rhodolite',
      alt: 'Rhodolite garnet with a vibrant raspberry-red tone',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-rhodolite.png',
      url: 'gemstones/rhodolite',
    },
    {
      id: 'ruby',
      title: 'Ruby',
      alt: 'Ruby gemstone with a bold, deep red brilliance',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-ruby.png',
      url: 'gemstones/ruby',
    },
    {
      id: 'sapphire',
      title: 'Sapphire',
      alt: 'Sapphire gemstone with a classic royal blue sparkle',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-sapphire.png',
      url: 'gemstones/sapphire',
    },
    {
      id: 'fancy-sapphire',
      title: 'Fancy Sapphire',
      alt: 'Fancy sapphire with a striking yellow shade',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-fancy-sapphire.png',
      url: 'gemstones/fancy-sapphire',
    },
    {
      id: 'star-sapphire',
      title: 'Star Sapphire',
      alt: 'Star sapphire with a six-rayed star effect in blue',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-star-sapphire.png',
      url: 'gemstones/star-sapphire',
    },
    {
      id: 'spinel',
      title: 'Spinel',
      alt: 'Spinel gemstone with a bright red luster',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-spinel.png',
      url: 'gemstones/spinel',
    },
    {
      id: 'tanzanite',
      title: 'Tanzanite',
      alt: 'Tanzanite gemstone with a unique blue-violet shimmer',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-tanzanite.png',
      url: 'gemstones/tanzanite',
    },
    {
      id: 'topaz',
      title: 'Topaz',
      alt: 'Topaz gemstone with a clear, sky-blue glow',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-topaz.png',
      url: 'gemstones/topaz',
    },
    {
      id: 'tourmaline',
      title: 'Tourmaline',
      alt: 'Tourmaline gemstone with a vivid pink and green bi-color pattern',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-tourmaline.png',
      url: 'gemstones/tourmaline',
    },
    {
      id: 'tsavorite',
      title: 'Tsavorite',
      alt: 'Tsavorite garnet with a lush green hue',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-tsavorite.png',
      url: 'gemstones/tsavorite',
    },
  ],
  shape: [
    {
      id: 'rb',
      title: 'Round',
      alt: 'Close-up of a sparkling round-cut diamond on a black velvet background',
      url: 'gemstones/round',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-round.svg',
    },
    {
      id: 'pr',
      title: 'Princess',
      alt: 'Princess-cut diamond with sharp square edges on a white surface',
      url: 'gemstones/princess',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-princess.svg',
    },
    {
      id: 'em',
      title: 'Emerald',
      alt: 'Emerald-cut diamond with elongated rectangular facets',
      url: 'gemstones/emerald',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-emerald.svg',
    },
    {
      id: 'as',
      title: 'Asscher',
      alt: 'Asscher-cut diamond with a stepped square design',
      url: 'gemstones/asscher',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-asscher.svg',
    },
    {
      id: 'mq',
      title: 'Marquise',
      alt: 'Marquise-cut diamond with pointed ends and a boat-like shape',
      url: 'gemstones/marquise',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-marquise.svg',
    },
    {
      id: 'cu',
      title: 'Cushion',
      alt: 'Cushion-cut diamond with soft rounded edges',
      url: 'gemstones/cushion',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-cushion.svg',
    },
    {
      id: 'ra',
      title: 'Radiant',
      alt: 'Radiant-cut diamond with brilliant facets and trimmed corners',
      url: 'gemstones/radiant',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-radiant.svg',
    },
    {
      id: 'pe',
      title: 'Pear',
      alt: 'Pear-shaped diamond with a teardrop silhouette',
      url: 'gemstones/pear',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-pear.svg',
    },
    {
      id: 'ov',
      title: 'Oval',
      alt: 'Oval-cut diamond with an elongated elegant shape',
      url: 'gemstones/oval',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-oval.svg',
    },
    {
      id: 'hs',
      title: 'Heart',
      alt: 'Heart-shaped diamond with a romantic curved outline',
      url: 'gemstones/heart',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-heart.svg',
    },
    {
      id: 'oc',
      title: 'Others',
      alt: 'Others gemstone shapes',
      url: 'gemstones/others',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-shape-others.svg',
    },
  ] as TShape[],
  clarity: [
    {
      id: 'EC1',
      title: 'EC1',
    },
    {
      id: 'EC2',
      title: 'EC2',
    },
    {
      id: 'I1',
      title: 'I1',
    },
    {
      id: 'I2',
      title: 'I2',
    },
    {
      id: 'None',
      title: 'None',
    },
    {
      id: 'Opaque',
      title: 'Opaque',
    },
    {
      id: 'Translucent',
      title: 'Translucent',
    },
    {
      id: 'VI1',
      title: 'VI1',
    },
    {
      id: 'VI2',
      title: 'VI2',
    },
  ],
  color: [
    {
      id: 'yellow',
      title: 'Yellow',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-yellow.png',
    },
    {
      id: 'pink',
      title: 'Pink',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-pink.png',
    },
    {
      id: 'purple',
      title: 'Purple',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-purple.png',
    },
    {
      id: 'red',
      title: 'Red',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-red.png',
    },
    {
      id: 'blue',
      title: 'Blue',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-blue.png',
    },
    {
      id: 'green',
      title: 'Green',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-green.png',
    },
    {
      id: 'orange',
      title: 'Orange',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-orange.png',
    },
    {
      id: 'brown',
      title: 'Brown',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-brown.png',
    },
    {
      id: 'white',
      title: 'White',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-white.png',
    },
    {
      id: 'gray',
      title: 'Gray',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-gray.png',
    },
    {
      id: 'black',
      title: 'Black',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-black.png',
    },
    {
      id: 'mixed',
      title: 'Mixed',
      image:
        'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/gemstone/gemstone-colour-mixed.png',
    },
  ],
};

export const stepsBarDefault = [
  {
    id: '1',
    name: 'Diamond',
    href: setUrl('loose-diamonds/lab-grown-diamonds'),
    image:
      'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/step-diamond.svg',
    isImage: false,
    status: 'complete',
    onClick: () => {},
  },
  {
    id: '2',
    name: 'Setting',
    href: setUrl('engagement-rings/setting'),
    image:
      'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/step-ring-setting.svg',
    isImage: false,
    status: 'current',
    onClick: () => {},
  },
  {
    id: '3',
    name: 'Complete',
    href: '#',
    image:
      'https://images-michaeltrio.s3.ap-southeast-1.amazonaws.com/icons/step-complete-ring.svg',
    isImage: false,
    onClick: () => {},
    status: 'upcoming',
  },
] as TSteps[];

export const ProductInfoFields = {
  info1: {
    ring_width: 'Ring Width',
    ring_height: 'Ring Height',
    ring_diameter: 'Ring Diameter',
    ring_length: 'Ring Length',
    confirmfit: 'Comfort Fit',
    engraveable: 'Engravable',
    ring_rhodium_plated: 'Rhodium Plated',
    clasp_type2: 'Clasp Type',
    chain_type2: 'Chain Type',
    chain_legnth: 'Chain Length',
    backing_type: 'Backing Type',
  },

  info2: {
    sst1_gemstone: 'Stone Type',
    sst1_shape: 'Stone Shape',
    sst1_settingtype: 'Setting Type',
    sst1_number_diamonds: 'Estimated Number Of Stones',
    sst1_weight_estimated: 'Average Carat Total Weight',
    sst1_average_color: 'Average Colour',
    sst1_average_clarity: 'Average Clarity',
    sst1_average_cut: 'Average Cut',
    sst2_number_diamonds: 'Number Of Stones',
    sst2_weight_estimated: 'Carat Total Weight',
    sst3_number_diamonds: 'Number Of Stones',
    sst3_weight_estimated: 'Carat Total Weight',
  },

  info3: {
    sst2_gemstone: 'Stone Type',
    sst2_shape: 'Stone Shape',
    sst2_settingtype: 'Setting Type',
    sst2_number_diamonds: 'Estimated Number Of Stones',
    sst2_weight_estimated: 'Minimum Carat Total Weight',
    sst2_average_color: 'Average Colour',
    sst2_average_clarity: 'Average Clarity',
    sst2_average_cut: 'Average Cut',
  },

  info4: {
    sst3_gemstone: 'Stone Type',
    sst3_shape: 'Stone Shape',
    sst3_settingtype: 'Setting Type',
    sst3_number_diamonds: 'Estimated Number Of Stones',
    sst3_weight_estimated: 'Minimum Carat Total Weight',
    sst3_average_color: 'Average Colour',
    sst3_average_clarity: 'Average Clarity',
    sst3_average_cut: 'Average Cut',
  },
};

export const PaymentMethods = {
  atome_payment_gateway: 'Atome',
  stripe_payments: 'Stripe Payments',
  shop_gateway: 'Manual Payment',
};

import CustomIcon from '@components/icons/custom-made-icon';
import CartIcon from '@components/icons/cart-icon';
import CertIcon from '@components/icons/featured/cert-icon';
import ParcelIcon from '@components/icons/featured/parcel-icon';
import ReturnIcon from '@components/icons/featured/return-icon';
import SecureIcon from '@components/icons/featured/secure-icon';
import QualityIcon from '@components/icons/quality-icon';
import { TOption } from '@components/types';

export const guaranteeInfo = {
  gemstones: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
  ],
  'engagement-rings': [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
  ],
  'wedding-bands': [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
  ],
  jewellery: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
  ],
  'loose-diamonds': [
    { text: 'Lifetime Diamond Ring Upgrade', icon: SecureIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  diamonds: [
    { text: 'Lifetime Diamond Ring Upgrade', icon: SecureIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  'loose-diamonds-diamond': [
    { text: 'Lifetime Diamond Ring Upgrade', icon: SecureIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  'loose-diamonds-labgrown': [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  'loose-diamonds-natural_coloured': [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  'loose-diamonds-labgrown_coloured': [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  golds: [
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Buy online, Pick-up In-store', icon: CartIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
  ],
  // product single page
  diamond: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
    { text: 'Lifetime Diamond Ring Upgrade', icon: SecureIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  labgrown: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  natural_coloured: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  labgrown_coloured: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
    { text: 'Ethically sourced & certified diamonds', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
  gemstone: [
    { text: 'Custom Made Orders Available', icon: CustomIcon },
    { text: 'Quality Craftsmanship', icon: QualityIcon },
    { text: 'Ethically sourced & certified gemstones', icon: CertIcon },
    { text: 'Enjoy quality packaging and free shipping', icon: ParcelIcon },
    { text: 'Hassle-free, 14-day returns', icon: ReturnIcon },
  ],
};

export const EngravingFontsOption = [
  { name: '', value: 'No engrave' },
  { name: 'Harrington', value: 'Harrington' },
  { name: 'Lauren Script', value: 'Lauren Script' },
  { name: 'Times New Roman', value: 'Times New Roman' },
  { name: 'Tunga', value: 'Tunga' },
  { name: 'Monotype Corsiva', value: 'Monotype Corsiva' },
] as TOption[];

// make a sample product type
export const csProduct: TProduct = {
  id: 1,
  sku: 'CS-001',
  name: 'Product Name',
  attribute_set_id: 0,
  price: 0,
  status: 0,
  visibility: 0,
  type_id: '',
  created_at: '',
  updated_at: '',
  weight: 0,
  extension_attributes: {
    website_ids: [124],
    category_links: [],
    stock_item: {
      item_id: 0,
      product_id: 0,
      stock_id: 0,
      qty: 0,
      is_in_stock: false,
      is_qty_decimal: false,
      show_default_notification_message: false,
      use_config_min_qty: false,
      min_qty: 0,
      use_config_min_sale_qty: 0,
      min_sale_qty: 0,
      use_config_max_sale_qty: false,
      max_sale_qty: 0,
      use_config_backorders: false,
      backorders: 0,
      use_config_notify_stock_qty: false,
      notify_stock_qty: 0,
      use_config_qty_increments: false,
      qty_increments: 0,
      use_config_enable_qty_inc: false,
      enable_qty_increments: false,
      use_config_manage_stock: false,
      manage_stock: false,
      low_stock_date: '',
      is_decimal_divided: false,
      stock_status_changed_auto: 0,
    },
  },
  product_links: [],
  options: [],
  media_gallery_entries: [],
  tier_prices: [],
  custom_attributes: [],
};

export const ProductDeliveryEstimatedDays = [
  {
    stockCode: 'diamond',
    deliveryDays: 14,
    stock: 'supplier',
  },
  {
    stockCode: 'diamond',
    deliveryDays: 7,
    stock: 'local',
  },
  {
    stockCode: 'setting',
    deliveryDays: 14,
    stock: 'local',
  },
  {
    stockCode: 'no-stock',
    deliveryDays: 57,
    stock: 'no-stock',
  },
  {
    stockCode: 'has-stock',
    deliveryDays: 5,
    stock: 'local',
  },
];

export const AllCategories = {
  119: 'Engagement Rings > Channel-set',
  122: 'Engagement Rings > Halo',
  118: 'Engagement Rings > Pave',
  120: 'Engagement Rings > Side-stone',
  117: 'Engagement Rings > Solitaire',
  121: 'Engagement Rings > Vintage',
  5636: 'Engagement Rings > Three-stone',
  5637: 'Engagement Rings > Dual-pave',
  5638: 'Engagement Rings > Man-engagement-ring',
  5639: 'Engagement Rings > Curve',
  5640: 'Engagement Rings > Twisted',
  5641: 'Engagement Rings > Hidden-diamond',
  5642: 'Engagement Rings > Hidden-halo',
  5643: 'Engagement Rings > Vow-of-love',
  89: 'Wedding Bands > Women > Classic',
  91: 'Wedding Bands > Women > Diamond',
  92: 'Wedding Bands > Women > Eternity',
  93: 'Wedding Bands > Women > Alternative',
  165: 'Wedding Bands > Women > Half-eternity',
  166: 'Wedding Bands > Women > Full-eternity',
  182: 'Wedding Bands > Women > Plain',
  95: 'Wedding Bands > Men > Classic',
  96: 'Wedding Bands > Men > Diamond',
  97: 'Wedding Bands > Men > Alternative',
  181: 'Wedding Bands > Men > Plain',
  30: 'Jewellery > Necklaces > Diamonds',
  31: 'Jewellery > Necklaces > Gemstones',
  32: 'Jewellery > Necklaces > Pearls',
  115: 'Jewellery > Necklaces > Golds',
  111: 'Jewellery > Necklaces > Engravables',
  142: 'Jewellery > Necklaces > Alphabet',
  156: 'Jewellery > Necklaces > Chains',
  185: 'Jewellery > Necklaces > Vermeil',
  196: 'Jewellery > Pendants > Casing',
  34: 'Jewellery > Earrings > Diamond',
  35: 'Jewellery > Earrings > Gemstone',
  36: 'Jewellery > Earrings > Pearls',
  123: 'Jewellery > Earrings > Diamond-stud',
  188: 'Jewellery > Earrings > Golds',
  186: 'Jewellery > Earrings > Vermeil',
  38: 'Jewellery > Bracelets > Diamond',
  39: 'Jewellery > Bracelets > Gemstone',
  40: 'Jewellery > Bracelets > Pearls',
  113: 'Jewellery > Bracelets > Engravable',
  116: 'Jewellery > Bracelets > Gold',
  176: 'Jewellery > Bracelets > Tennis-bracelets',
  187: 'Jewellery > Bracelets > Vermeil',
  107: 'Jewellery > Rings > Gemstone',
  108: 'Jewellery > Rings > Diamond',
  109: 'Jewellery > Rings > Eternity',
  110: 'Jewellery > Rings > Pearls',
  114: 'Jewellery > Rings > Gold',
  1191: 'Jewellery > Rings > Dainty',
  180: 'Jewellery > Rings > Plain',
  130: 'Jewellery > Rings > Color-diamond',
  177: 'Jewellery > Care-collectibles > Cleaning-kit',
  178: 'Jewellery > Care-collectibles > Box',
  179: 'Jewellery > Pendants',
  151: 'Golds > Pendants',
  152: 'Golds > Bars',
  153: 'Golds > Coins',
  154: 'Golds > Chains',
  157: 'Golds > Bracelets',
  158: 'Golds > Charms',
  159: 'Golds > Necklaces',
  160: 'Golds > Bangles',
  161: 'Golds > Earrings',
  162: 'Golds > Rings',
  172: 'Golds > Baby-bangles',
  174: 'Golds > Collections > Si-dian-jin',
  139: 'Natural Diamonds',
  143: 'Natural Diamonds',
  147: 'Natural Diamonds',
  140: 'Labgrown Diamonds',
  144: 'Labgrown Diamonds',
  148: 'Labgrown Diamonds',
  146: 'Gemstones',
  141: 'Gemstones',
};

export const EngraveCharLimit = 12;
export const EngraveCharLimitSmall = 6;

export const SkusEngraveLimit = [
  'sbar-gbr',
  'sbar-gp',
  'heart-gp',
  'round-gp',
  'bar-gp',
  'dbar-gbr',
  'rdinf-gbr',
];

export const ProductOptionVariables = {
  'Zodiac Sign': 'zodiac_sign',
  Alphabet: 'alphabet',
  Size: 'size',
  'Total Carat Weight': 'total_carat_weight',
  'Diamond Type': 'diamond_type',
};
